var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.isLoading && _vm.productColorOptions.length && !_vm.error.hasError
      ? _c(
          "div",
          _vm._l(_vm.productColorOptions, function(colorOption, index) {
            return colorOption.collection
              ? _c("ProductBlock", {
                  key: colorOption.uid,
                  attrs: {
                    samplesInCart: _vm.samplesInCart,
                    colorOption: colorOption,
                    index: index
                  },
                  on: { "add-to-cart": _vm.handleATC }
                })
              : _vm._e()
          }),
          1
        )
      : _vm.error.hasError && !_vm.isLoading
      ? _c("div", { staticClass: "no-results error-state" }, [
          _c("p", [
            _vm._v(
              "There was an error, please try again or contact us if this keeps happening."
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "error-message" }, [
            _vm._v("Error: " + _vm._s(_vm.error.message))
          ])
        ])
      : !_vm.isLoading && !_vm.productColorOptions.length
      ? _c("div", { staticClass: "no-results" }, [
          _vm._v("\n        No results.\n    ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isLoading
      ? _c("div", { staticClass: "small-12 text-center" }, [
          _c(
            "a",
            {
              class: { loading: _vm.isLoading },
              style: {
                display: _vm.isLoading ? "block" : "none",
                opacity: _vm.isLoading ? 1 : 0
              },
              attrs: { id: "blog-load-more" }
            },
            [
              _vm._v("\n            Loading products...\n            "),
              _c("span", { staticClass: "more-blog-loading-icon" })
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }