var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.show
      ? _c("div", { ref: "modal", staticClass: "modal-cmp-content-wrapper" })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }