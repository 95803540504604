var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "product-filter-container",
      class: { "small-12 large-4 columns": true, open: _vm.popoutOpen }
    },
    [
      _c(
        "div",
        {
          staticClass: "filter-toggle",
          on: {
            click: function($event) {
              return _vm.$emit("togglePopout")
            }
          }
        },
        [
          _c("h4", [_vm._v("FILTER")]),
          _vm._v(" "),
          _c("img", { attrs: { src: _vm.icons.filter } })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "filter-popout" }, [
        _c(
          "div",
          {
            staticClass: "close-icon",
            on: {
              click: function($event) {
                return _vm.$emit("togglePopout")
              }
            }
          },
          [_c("img", { attrs: { src: _vm.icons.filterClose } })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "filter-row" }, [
          _c("h5", [_vm._v("COLOR:")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "color-spectrum" },
            [
              _c("ColorPoints", {
                attrs: { colors: _vm.filters.productColors }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "color-droplets" },
            _vm._l(_vm.filters.productColors, function(color) {
              return _c("li", { key: color.id }, [
                _c("label", { attrs: { for: color.id } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.metaFilters.colorFilters,
                        expression: "metaFilters.colorFilters",
                        modifiers: { number: true }
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      id: color.id,
                      name: "colorOptions"
                    },
                    domProps: {
                      value: color.id,
                      checked: Array.isArray(_vm.metaFilters.colorFilters)
                        ? _vm._i(_vm.metaFilters.colorFilters, color.id) > -1
                        : _vm.metaFilters.colorFilters
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.metaFilters.colorFilters,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm._n(color.id),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.metaFilters,
                                "colorFilters",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.metaFilters,
                                "colorFilters",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.metaFilters, "colorFilters", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm._m(0, true),
                  _vm._v(" "),
                  _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(color.title))
                  ])
                ])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "clear-category-filters",
              on: {
                click: function($event) {
                  return _vm.clearMetaCategoryFilters(
                    _vm.filters.productPrices,
                    "colorFilters"
                  )
                }
              }
            },
            [_vm._v("Clear All Color Filters")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "filter-row" }, [
          _c("h5", [_vm._v("WIDTH:")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "green-checkboxes" },
            _vm._l(_vm.filters.productWidths, function(width) {
              return _c("li", { key: width.id }, [
                _c("label", { attrs: { for: width.id } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.metaFilters.widthOptions,
                        expression: "metaFilters.widthOptions",
                        modifiers: { number: true }
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      id: width.id,
                      name: "widthOptions"
                    },
                    domProps: {
                      value: width.id,
                      checked: Array.isArray(_vm.metaFilters.widthOptions)
                        ? _vm._i(_vm.metaFilters.widthOptions, width.id) > -1
                        : _vm.metaFilters.widthOptions
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.metaFilters.widthOptions,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm._n(width.id),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.metaFilters,
                                "widthOptions",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.metaFilters,
                                "widthOptions",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.metaFilters, "widthOptions", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(width.title))
                  ])
                ])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "clear-category-filters",
              on: {
                click: function($event) {
                  return _vm.clearMetaCategoryFilters(
                    _vm.filters.productWidths,
                    "widthOptions"
                  )
                }
              }
            },
            [_vm._v("Clear All Width Filters")]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter-row" },
          [
            _c("h5", [_vm._v("COLLECTION:")]),
            _vm._v(" "),
            _vm._l(_vm.filters.productCollectionCategories, function(
              collectionCategory
            ) {
              return _c(
                "ul",
                { staticClass: "green-checkboxes full-width" },
                [
                  collectionCategory.children.length
                    ? _c("div", [_vm._v(_vm._s(collectionCategory.title))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(collectionCategory.children, function(collection) {
                    return collection.children.length == 0
                      ? _c("li", { key: collection.id }, [
                          _c("label", { attrs: { for: collection.id } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.metaFilters.collectionOptions,
                                  expression: "metaFilters.collectionOptions",
                                  modifiers: { number: true }
                                }
                              ],
                              attrs: {
                                type: "checkbox",
                                id: collection.id,
                                name: "collectionOptions"
                              },
                              domProps: {
                                value: collection.id,
                                checked: Array.isArray(
                                  _vm.metaFilters.collectionOptions
                                )
                                  ? _vm._i(
                                      _vm.metaFilters.collectionOptions,
                                      collection.id
                                    ) > -1
                                  : _vm.metaFilters.collectionOptions
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.metaFilters.collectionOptions,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = _vm._n(collection.id),
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.metaFilters,
                                          "collectionOptions",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.metaFilters,
                                          "collectionOptions",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.metaFilters,
                                      "collectionOptions",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "checkmark" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "label" }, [
                              _vm._v(_vm._s(collection.title))
                            ])
                          ])
                        ])
                      : _vm._e()
                  })
                ],
                2
              )
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "clear-category-filters",
                on: {
                  click: function($event) {
                    return _vm.clearMetaCategoryFilters(
                      _vm.filters.productCollectionCategories,
                      "collectionOptions"
                    )
                  }
                }
              },
              [_vm._v("Clear All Collection Filters")]
            )
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "filter-row" }, [
          _c("h5", [_vm._v("SPECIES:")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "green-checkboxes" },
            _vm._l(_vm.filters.productSpecies, function(species) {
              return _c("li", { key: species.id }, [
                _c("label", { attrs: { for: species.id } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.metaFilters.speciesOptions,
                        expression: "metaFilters.speciesOptions",
                        modifiers: { number: true }
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      id: species.id,
                      name: "speciesOptions"
                    },
                    domProps: {
                      value: species.id,
                      checked: Array.isArray(_vm.metaFilters.speciesOptions)
                        ? _vm._i(_vm.metaFilters.speciesOptions, species.id) >
                          -1
                        : _vm.metaFilters.speciesOptions
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.metaFilters.speciesOptions,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm._n(species.id),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.metaFilters,
                                "speciesOptions",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.metaFilters,
                                "speciesOptions",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.metaFilters, "speciesOptions", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(species.title))
                  ])
                ])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "clear-category-filters",
              on: {
                click: function($event) {
                  return _vm.clearMetaCategoryFilters(
                    _vm.filters.productSpecies,
                    "speciesOptions"
                  )
                }
              }
            },
            [_vm._v("Clear All Species Filters")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "filter-row" }, [
          _c("h5", [_vm._v("PRICE:")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "green-checkboxes prices" },
            _vm._l(_vm.filters.productPrices, function(price) {
              return _c("li", { key: price.id }, [
                _c("label", { attrs: { for: price.id } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.metaFilters.priceOptions,
                        expression: "metaFilters.priceOptions",
                        modifiers: { number: true }
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      id: price.id,
                      name: "priceOptions"
                    },
                    domProps: {
                      value: price.id,
                      checked: Array.isArray(_vm.metaFilters.priceOptions)
                        ? _vm._i(_vm.metaFilters.priceOptions, price.id) > -1
                        : _vm.metaFilters.priceOptions
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.metaFilters.priceOptions,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = _vm._n(price.id),
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.metaFilters,
                                "priceOptions",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.metaFilters,
                                "priceOptions",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.metaFilters, "priceOptions", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkmark" }),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "label" },
                    _vm._l(4, function(n) {
                      return _c(
                        "span",
                        {
                          key: n * 437,
                          class: { curr: true, dark: n <= price.title.length }
                        },
                        [_vm._v("$")]
                      )
                    }),
                    0
                  )
                ])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "clear-category-filters",
              on: {
                click: function($event) {
                  return _vm.clearMetaCategoryFilters(
                    _vm.filters.productPrices,
                    "priceOptions"
                  )
                }
              }
            },
            [_vm._v("Clear All Price Filters")]
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button clear-all-filters",
            attrs: { type: "button" },
            on: { click: _vm.clearAllFilters }
          },
          [_vm._v("CLEAR ALL FILTERS")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "checkmark droplet" }, [
      _c("span", { staticClass: "checkmark-icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }