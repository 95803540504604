var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "featuredprojects" },
    [
      _c(
        "swiper",
        { ref: "mySwiper", attrs: { options: _vm.swiperOptions } },
        _vm._l(_vm.projects, function(project) {
          return _c(
            "swiper-slide",
            { key: project.id },
            [
              _c("project-card", {
                attrs: {
                  embedded: true,
                  description: project.projectCardCopy,
                  slug: project.slug,
                  photo: project.featuredPhoto,
                  title: project.title,
                  products: project.featuredProducts.data,
                  location: project.location
                }
              })
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "prevButton", staticClass: "swiper-button-prev" }),
      _vm._v(" "),
      _c("div", { ref: "nextButton", staticClass: "swiper-button-next" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }