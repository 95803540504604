var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { id: "sample-nav-contain", "data-magellan-expedition": "fixed" }
    },
    [
      _c("div", { staticClass: "sample-sticky-bar" }, [
        _c("div", { staticClass: "row inner collapse" }, [
          _c("div", { staticClass: "columns small-12 medium-5 large-5" }, [
            _c("div", { staticClass: "selected-samples" }, [
              _c("span", { staticClass: "num-selected-samples" }, [
                _vm._v(_vm._s(_vm.cartInfo.totalQty))
              ]),
              _vm._v("/"),
              _c("span", { attrs: { id: "max-samples" } }, [
                _vm._v(_vm._s(_vm.max))
              ]),
              _vm._v(" samples selected\n                ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns small-12 medium-7 large-7" }, [
            _c("div", { staticClass: "samples-cart" }, [
              _c("div", { staticClass: "left groupLR" }, [
                _c("ul", [
                  _c(
                    "li",
                    { class: { "for-step1": true, hide: _vm.step !== 1 } },
                    [_c("span", [_vm._v("PROCEED TO CHECKOUT")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      class: { "for-step2": true, hide: _vm.step !== 2 },
                      on: {
                        click: function($event) {
                          return _vm.$emit("go-to-checkout")
                        }
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button button-point point-right",
                          attrs: { "data-step2": "", id: "proceed-sample-btn" }
                        },
                        [
                          _vm._v(
                            "\n                                    PROCEED TO CHECKOUT\n                                "
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right groupLR" }, [
                _c("div", { staticClass: "cart" }, [
                  _c("i", { staticClass: "icon-cart-sample" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "in-cart" }, [
                    _vm._v(_vm._s(_vm.cartInfo.totalQty))
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }