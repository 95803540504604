var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading && _vm.samples.length
    ? _c(
        "ul",
        {
          staticClass:
            "large-block-grid-4 medium-block-grid-3 product-list product-list-large"
        },
        _vm._l(_vm.samples, function(sample, index) {
          return _c("sample", {
            key: sample.id,
            tag: "li",
            attrs: { index: index, sample: sample },
            on: {
              "add-sample": _vm.handleAddSample,
              "remove-sample": _vm.handleRemoveSample
            }
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }