var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoadingMore && !_vm.isLoading
    ? _c(
        "button",
        {
          staticClass: "button load-more-products",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("loadMore")
            }
          }
        },
        [_vm._v("\n    LOAD MORE\n")]
      )
    : _c(
        "a",
        {
          class: { loading: _vm.isLoadingMore },
          style: {
            display: _vm.isLoadingMore ? "block" : "none",
            opacity: _vm.isLoadingMore ? 1 : 0,
            textAlign: "center"
          }
        },
        [_vm._v("\n    Loading more...\n    "), _c("loader")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }